import { Elements } from "@stripe/react-stripe-js"
import CheckoutForm from "../checkout"
import React from "react"
import { loadStripe } from "@stripe/stripe-js"


const {
  GATSBY_KEY_STRIPE
} = process.env

const stripePromise = loadStripe(GATSBY_KEY_STRIPE)


const Payment = ({ paymentMethod, clientSecret, formulaId, onCheckPayment }) => {

  const appearance = {
    theme: "stripe"
  }

  const options = {
    clientSecret,
    appearance
  }

  return (
    <>
      {
        paymentMethod === "creditCard" && clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm formulaId={formulaId}/>
          </Elements>
        )
      }
      {
        paymentMethod === "check" && (
          <div className="payment-check">
            <div className="payment-check__grid">
              <div className="numberOne payment-check__number">1</div>
              <div className="numberTwo payment-check__number">2</div>
              <div className="numberThree payment-check__number">3</div>
              <div className="textOne payment-check__text">Remplissez votre chèque avec le <b>montant de
                votre cotisation et à l’ordre de “Amicale Saint Stanislas”</b>.
              </div>
              <div className="textTwo payment-check__text">Envoyez votre chèque dans une enveloppe
                affranchie, à l’adresse suivante : <br/>
                <b> Amicale St Stan – 2 rue Saint Stanislas BP 40905, 44009 NANTES Cedex 1</b>.
              </div>
              <div className="textThree payment-check__text"><b>Votre adhésion sera définitivement prise
                en compte une fois votre chèque reçu</b>.
              </div>
            </div>
            <button onClick={() => onCheckPayment()}>Valider</button>
          </div>
        )
      }
    </>
  )
}

export default Payment
