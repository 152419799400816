import React, { Component } from "react"
import Layout from "../components/layout"
import Reasons from "../components/reasons"
import Statistics from "../components/statistics"
import Entreprises from "../components/entreprises"
import { graphql } from "gatsby"
import PageHeader from "../components/page-header"

import {
  FormSubscription,
  FormSteps,
  SelectedFormula,
  ChosePaymentMethods,
  PaymentConfirmation,
  Payment
} from "../components/nous-rejoindre"


class NousRejoindre extends Component {

  constructor(props) {
    super(props)

    const params = new URLSearchParams(props.location.search)
    const paymentIntentClientSecret = params.get("payment_intent_client_secret")

    this.state = {
      selectedFormula: undefined,
      step: paymentIntentClientSecret ? 3 : 0,
      paymentMethod: undefined,
      paymentSuccess: undefined,
      sessionKey: undefined,
      fields: {
        address: "",
        birth: "",
        city: "",
        email: "",
        givenName: "",
        name: "",
        phone: "",
        postCode: "",
        promo: ""
      }
    }

  }


  componentDidMount() {
    const { location: { search }, data } = this.props
    const params = new URLSearchParams(search)

    const urlFormulaId = params.get("formula_id")
    if (urlFormulaId) {
      const page = data.allContentfulFormules.nodes[0]
      this.setState({ selectedFormula: page.options.find(option => option.stripeId === urlFormulaId) })
    }

    const urlRedirect_status = params.get("redirect_status")
    if (urlRedirect_status) {

      const success = urlRedirect_status === "succeeded"

      this.setState({ paymentSuccess: success })

      const savedFields = localStorage.getItem("savedFields")

      if (success && savedFields) {
        fetch("https://amicale-backend.herokuapp.com/save-subscription", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: savedFields
        })
          .then((res) => res.json())
          .then((data) => {
            localStorage.removeItem("savedFields")
          })
      }
    }
  }

  nextStep() {
    const { step } = this.state
    this.setState({ step: step + 1 })
  }

  onSubmit() {
    const { selectedFormula, fields } = this.state

    const formattedFields = {
      "givenName": fields.givenName,
      "name": fields.name,
      "address": fields.address,
      "postCode": fields.postCode,
      "city": fields.city,
      "promo": fields.promo,
      "phone": fields.phone,
      "email": fields.email,
      "birth": fields.birth,
      "type": selectedFormula.secondTitre,
      "price_id": selectedFormula.stripeId
    }

    if (!selectedFormula.stripeId) {
      this.setState({ step: 3, paymentSuccess: true })

      fetch("https://amicale-backend.herokuapp.com/save-subscription", {
        method: "POST",
        headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
        body: JSON.stringify({ fields: formattedFields })
      })
        .then((res) => res.json())
        .then((data) => console.log(data))
    } else {
      this.nextStep()
      localStorage.setItem("savedFields", JSON.stringify({ fields: formattedFields }))
    }
  }

  async onChosePaymentMethod(paymentMethod) {

    const { selectedFormula } = this.state

    this.nextStep()
    this.setState({ paymentMethod: paymentMethod })

    if (paymentMethod === "creditCard") {
      fetch("https://amicale-backend.herokuapp.com/create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ item: { id: selectedFormula.stripeId } })
      })
        .then((res) => res.json())
        .then((data) => this.setState({ clientSecret: data.clientSecret }))
    }
  }

  validateInputs() {
    const { fields, focusedInput } = this.state

    let disabled = false

    const showError = (key, color) => {
      const input = document.getElementById(key)
      if (input) {
        input.style.color = color
        input.style.borderColor = color
      }
    }

    Object.entries(fields).forEach(([key, value]) => {

      if (!value) {
        disabled = true
        return
      }

      if (key === "name" && !value.match(/^[a-zA-Z\s\-]+$/)) {
        disabled = true
        if (focusedInput !== "name") {
          showError("name", "red")
        }
      }

      if (key === "givenName" && !value.match(/^[a-zA-Z\s\-]+$/)) {
        disabled = true
        if (focusedInput !== "givenName") {
          showError("givenName", "red")
        }
      }

      if (key === "city" && !value.match(/^[a-zA-Z\s\-]+$/)) {
        disabled = true
        if (focusedInput) {
          showError("city", "red")
        }
      }

      if (key === "postCode" && !value.match(/^[0-9]+$/)) {
        disabled = true
        if (focusedInput !== "postCode") {
          showError("postCode", "red")
        }
      }

      const phoneRegex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
      if (key === "phone" && !phoneRegex.test(value)) {
        disabled = true
        if (focusedInput !== "phone") {
          showError("phone", "red")
        }
      }

      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if ((key === "email" && !emailRegex.test(value.toLowerCase()))) {
        disabled = true
        if (focusedInput !== "email") {
          showError("email", "red")
        }
      }
    })

    return disabled
  }

  updateInput(field, e) {
    const { fields } = this.state
    const value = e.target.value
    const input = document.getElementById(field)

    input.style.color = (!value && !input.value) ? "#D8D8D8" : "#455880"
    input.style.borderColor = (!value && !input.value) ? "#D8D8D8" : "#455880"

    fields[field] = value
    this.setState({ fields: fields })
  }

  reset() {
    this.setState({
      selectedFormula: false,
      paymentMethod: undefined,
      paymentSuccess: undefined,
      initialColorInputs: undefined,
      step: 0,
      fields: {
        address: "",
        birth: "",
        city: "",
        email: "",
        givenName: "",
        name: "",
        phone: "",
        postCode: "",
        promo: ""
      }
    })
  }

  back() {
    const { step } = this.state

    if (step === 1) {
      this.setState({ step: step - 1, initialColorInputs: "#455880" })
    }

    if (step === 2) {
      this.setState({ paymentMethod: undefined, step: step - 1 })
    }

    if (step > 2) {
      this.reset()
    }
  }

  render() {
    const { data } = this.props
    const {
      selectedFormula,
      step,
      paymentMethod,
      paymentSuccess,
      clientSecret,
      fields,
      initialColorInputs
    } = this.state
    const page = data.allContentfulFormules.nodes[0]

    return (
      <Layout disablePrimaryCta={true}>
        <PageHeader data={page.titrePage}/>
        <div id="formules">
          <>
            <h3
              dangerouslySetInnerHTML={{
                __html: page.options.length + " " + page.secondTitre
              }}
            />
            <div className="items">
              {page.options.map((object, i) => (
                <Option data={object} onClick={() => console.log('ok')}/>
              ))}
            </div>
          </>
        </div>
        <Entreprises/>
        <Reasons/>
        <Statistics/>
      </Layout>
    )
  }
}


const Option = ({ data, onClick }) => {
  const pricing = data.pricing.split("/")

  return (
    <div id="option">
      <div className="texte">
        <span className="titre" style={{ color: data.couleur }}>
          {data.secondTitre}
        </span>
        <span className="description">{data.secondeDescription}</span>
        {pricing.length > 1 ? (
          <div className="pricing-container">
            <span className="pricing">{pricing[0]}</span>
            <span className="pricing-suffix">/{pricing[1]}</span>
          </div>
        ) : (
          <span className="pricing pricing-container">{pricing[0]}</span>
        )}
        {data.pricingDetails ? (
          <span className="pricing-details">{data.pricingDetails}</span>
        ) : (
          <div className="placeholder"/>
        )}
      </div>
      <div className="divider"/>
      <ul>
        {data.contenu.map((object, i) => (
          <li>
            <img srcSet={object.icone.file.url} alt={object.icone.description} className="icon"/>
            <span>{object.titre}</span>
          </li>
        ))}
      </ul>
      <div className="cta-container">
        <button hoverText="J'adhère" onClick={() => {
          window.open(data.googleForm);
        }}>Choisir cette formule</button>
      </div>
    </div>
  )
}

export default NousRejoindre

export const nousRejoindreQuery = graphql`
  {
    allContentfulFormules {
      nodes {
        secondTitre
        titrePage
        options {
          stripeId
          googleForm
          pricing
          pricingDetails
          secondTitre
          secondeDescription
          couleur
          cta {
            couleur
            texte
          }
          contenu {
            icone {
              description
              file {
                url
              }
            }
            titre
          }
        }
      }
    }
  }
`
